import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { PolicyCreatorState } from './pages/policy-creator/store/policy-creator.state';
import { MessengerService } from './services/messenger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  // keep refs to subscriptions to be able to unsubscribe later
  private statusChangeSubscription: Subscription;
  private routerSubscription: Subscription;
  destroyed = new Subject();
  cookieAllowed: string;
  isBrowser = typeof window !== `undefined`;

  constructor(
    private translocoService: TranslocoService,
    private gtmService: GoogleTagManagerService,
    private messengerService: MessengerService,
    private router: Router,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (environment.production) {
      this.addMarketingScripts();
    }

    this.router.events.pipe(takeUntil(this.destroyed)).subscribe((event) => {
      const policyCreatorState = this.store.selectSnapshot(
        PolicyCreatorState.state,
      );

      if (event['routerEvent']?.constructor.name === 'Q') {
        const newPolicyUrls = [
          '/new-policy/1',
          '/new-policy/2',
          '/new-policy/3',
          '/new-policy/4',
          '/new-policy/5',
        ];

        // FIXME: make it more flexible, implementation including cookies below
        if (
          event['routerEvent']?.url &&
          newPolicyUrls.includes(event['routerEvent'].url)
        ) {
          if (!policyCreatorState.sodanReplyHeads) {
            this.router.navigateByUrl('/apk/main');
          }
        }
      }
    });

    if (environment.production) {
      if (environment.homeFtp) {
        if (this.isBrowser) {
          if (location.protocol === 'http:') {
            window.location.href = location.href.replace('http', 'https');
          }

          if (location.href.startsWith('http://www.')) {
            window.location.href = location.href.replace(
              'http://www.',
              'https://',
            );
          }

          if (location.href.startsWith('https://www.')) {
            window.location.href = location.href.replace(
              'https://www.',
              'https://',
            );
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if (environment.production) {
      this.messengerService
        .load({
          name: 'messenger-chat',
          src: '',
        })
        .subscribe();
      this.gtmService.addGtmToDom();
    }
  }

  addMarketingScripts() {
    const fbRoot = document.createElement('div');
    fbRoot.id = 'fb-root';
    document.body.appendChild(fbRoot);

    const fbScript = document.createElement('script');
    fbScript.innerHTML = `window.fbAsyncInit = function () {
      FB.init({
        xfbml: true,
        version: 'v9.0',
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');`;
    document.body.appendChild(fbScript);

    const chatPluginCode = document.createElement('div');
    chatPluginCode.className = 'fb-customerchat';
    chatPluginCode.setAttribute('attribution', 'setup_tool');
    chatPluginCode.setAttribute('page_id', '103675818155120');
    chatPluginCode.setAttribute(
      'logged_in_greeting',
      'Dziękujemy za kontakt! Jakie informacje o GAP chciałbys się dowiedzieć?',
    );
    chatPluginCode.setAttribute(
      'logged_out_greeting',
      'Dziękujemy za kontakt!',
    );
    document.body.appendChild(chatPluginCode);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
