import * as moment from 'moment';
import {
  CreationLockPayload,
  Owner,
} from '../../shared/interfaces/creation-lock-payload.interface';
import { EnabledPortfolioWithOffers } from '../../shared/interfaces/enabled-portfolios-response.interface';
import { WarrantyCreatorStateModel } from '../store/warranty-creator.model';
import { calculatePremiumForAnnual } from './calculate-premium-for-annual';
import { calculatePremiumForInstalments } from './calculate-premium-for-instalments';
import { checkForInstalments } from './check-for-instalments';

export function mapOfferLockPayload(
  warrantyCreatorState: WarrantyCreatorStateModel,
  selectedOffer: EnabledPortfolioWithOffers,
): CreationLockPayload {
  try {
    const handlePremium = (): number => {
      switch (warrantyCreatorState.secondStepPayload.paymentType) {
        case 'PT_LS': {
          return (
            // selectedOffer.calculation.premiumDiscounted ?? selectedOffer.calculation.premiumSuggested
            selectedOffer.prices.premiumCalculated
          );
        }
        case 'PT_LS_M': {
          return calculatePremiumForInstalments(
            // selectedOffer.calculation.premiumDiscounted ?? selectedOffer.calculation.premiumSuggested,
            selectedOffer.prices.premiumCalculated,
            Number(
              warrantyCreatorState.secondStepPayload.policyPeriod.replace(
                'T_',
                '',
              ),
            ),
          ).premiumCalculated;
        }
        case 'PT_A': {
          return calculatePremiumForAnnual(
            // selectedOffer.calculation.premiumDiscounted ?? selectedOffer.calculation.premiumSuggested,
            selectedOffer.prices.premiumCalculated,
            warrantyCreatorState.secondStepPayload.policyPeriod,
          ).instalment;
        }
      }
    };

    const getPurchasedDate = () => {
      const isDateInRange = (date: string) => {
        return moment().diff(date) >= -60 && moment().diff(date) <= 120;
      };
      const now = new Date();
      const vehicleBuyDate =
        warrantyCreatorState.firstStepPayload.vehicleBuyDate;
      const acPolicyRenewalDate =
        warrantyCreatorState.firstStepPayload.acPolicyRenewalDate;
      let validDate;

      if (vehicleBuyDate && isDateInRange(vehicleBuyDate)) {
        validDate = vehicleBuyDate;
      }

      if (acPolicyRenewalDate && isDateInRange(acPolicyRenewalDate)) {
        validDate = acPolicyRenewalDate;
      }

      if (!validDate) {
        validDate = now;
      }

      return moment(validDate).format('YYYY-MM-DD');
    };

    const prepareNotes = (offer): string => {
      let notes: string = null;
      if (offer.discountLink) {
        notes = notes + `Link Afiliacyjny: ${offer.discountLink}\n`;
      }
      if (offer.discountCode) {
        notes = notes + `Kod Rabatowy: ${offer.discountCode}`;
      }

      return notes;
    };

    let owner: Owner;
    const insurer = warrantyCreatorState.thirdStepPayload.insurer;
    let insured = warrantyCreatorState.thirdStepPayload.insured;

    if (insured.sameAsInsurer) {
      insured = insurer;
    }

    if (warrantyCreatorState.thirdStepPayload.insured.sameAsInsurer) {
      owner = {
        contact: {
          type:
            warrantyCreatorState.thirdStepPayload.insurer.clientType ===
            'individual'
              ? 'person'
              : 'company',
          address: {
            addressLine1:
              warrantyCreatorState.thirdStepPayload.insurer.address1,
            addressLine2:
              warrantyCreatorState.thirdStepPayload.insurer.address2,
            street: warrantyCreatorState.thirdStepPayload.insurer.address1,
            city: warrantyCreatorState.thirdStepPayload.insurer.city,
            postCode: warrantyCreatorState.thirdStepPayload.insurer.postalCode,
            countryCode: 'PL',
          },
          identificationNumber:
            warrantyCreatorState.thirdStepPayload.insurer.idNumber,
        },
      };
      if (
        warrantyCreatorState.thirdStepPayload.insurer.clientType ===
        'individual'
      ) {
        owner.contact = {
          ...owner.contact,
          firstName: warrantyCreatorState.thirdStepPayload.insurer.firstName,
          lastName: warrantyCreatorState.thirdStepPayload.insurer.lastName,
        };
      } else {
        owner.contact = {
          ...owner.contact,
          companyName:
            warrantyCreatorState.thirdStepPayload.insurer.companyName,
        };
      }
    } else {
      owner = {
        contact: {
          type:
            warrantyCreatorState.thirdStepPayload.insured.clientType ===
            'individual'
              ? 'person'
              : 'company',
          address: {
            addressLine1:
              warrantyCreatorState.thirdStepPayload.insured.address1,
            addressLine2:
              warrantyCreatorState.thirdStepPayload.insured.address2,
            street: warrantyCreatorState.thirdStepPayload.insured.address1,
            city: warrantyCreatorState.thirdStepPayload.insured.city,
            postCode: warrantyCreatorState.thirdStepPayload.insured.postalCode,
            countryCode: 'PL',
          },
          identificationNumber:
            warrantyCreatorState.thirdStepPayload.insured.idNumber,
        },
      };

      if (
        warrantyCreatorState.thirdStepPayload.insured.clientType ===
        'individual'
      ) {
        owner.contact = {
          ...owner.contact,
          firstName: warrantyCreatorState.thirdStepPayload.insured.firstName,
          lastName: warrantyCreatorState.thirdStepPayload.insured.lastName,
        };
      } else {
        owner.contact = {
          ...owner.contact,
          companyName:
            warrantyCreatorState.thirdStepPayload.insured.companyName,
        };
      }
    }

    return {
      endorsementTypeCode: selectedOffer.calculation.endorsementTypeCode,
      productCode: selectedOffer.productCode,
      saleInitiatedOn: moment(
        selectedOffer.calculation.saleInitiatedOn,
      ).format(),
      instalmentSplittingSchemeCode: checkForInstalments(
        warrantyCreatorState.secondStepPayload,
      ),
      vehicleSnapshot: {
        modelCode: selectedOffer.calculation.vehicleSnapshot.modelCode,
        usageTypeCode: selectedOffer.calculation.vehicleSnapshot.usageTypeCode,
        usageCode: selectedOffer.calculation.vehicleSnapshot.usageCode,
        purchasePriceInputType:
          selectedOffer.calculation.vehicleSnapshot.purchasePriceInputType,
        purchasePrice: selectedOffer.calculation.vehicleSnapshot.purchasePrice,
        purchasePriceNet:
          selectedOffer.calculation.vehicleSnapshot.purchasePriceNet,
        purchasePriceVatReclaimableCode:
          selectedOffer.calculation.vehicleSnapshot
            .purchasePriceVatReclaimableCode,
        specialVehicle:
          selectedOffer.calculation.vehicleSnapshot.specialVehicle,
        categoryCode: warrantyCreatorState.firstStepPayload.vehicleType.code,
        vin: warrantyCreatorState.fourthStepPayload.vin,
        firstRegisteredOn: moment(
          warrantyCreatorState.firstStepPayload.vehicleRegDate,
        ).format('YYYY-MM-DD'),
        purchasedOn: getPurchasedDate(),
        mileage: selectedOffer.calculation.vehicleSnapshot.mileage,
        owners: [owner],
        engineSize: warrantyCreatorState.firstStepPayload.engineSize,
        mfgWarrantyInceptedOn:
          selectedOffer.calculation.vehicleSnapshot.mfgWarrantyInceptedOn,
        mfgWarrantyMileage:
          selectedOffer.calculation.vehicleSnapshot.mfgWarrantyMileage,
        mfgWarrantyTerm:
          selectedOffer.calculation.vehicleSnapshot.mfgWarrantyTerm,
        driveTypeCode: selectedOffer.calculation.vehicleSnapshot.driveTypeCode,
        engineTypeCode:
          selectedOffer.calculation.vehicleSnapshot.engineTypeCode,
      },
      options: {
        ...selectedOffer.calculation.options,
      },
      signatureTypeCode: 'AUTHORIZED_BY_SMS', // TODO: consider getting signatureTypeCodes from api
      premium: handlePremium(),
      inceptionDate: moment(selectedOffer.calculation.inceptionDate).format(
        'YYYY-MM-DD',
      ),
      client: {
        policyHolder: {
          type: insured.clientType === 'individual' ? 'person' : 'company',
          address: {
            addressLine1: insured.address1,
            addressLine2: insured.address2,
            street: insured.address1,
            city: insured.city,
            postCode: insured.postalCode,
            countryCode: 'PL',
          },
          firstName: insured.firstName,
          lastName: insured.lastName,
          companyName: insured.companyName,
          identificationNumber: insured.idNumber,
          phoneNumber: `${insured.phonePrefix + insured.phone}`,
          email: insured.email,
        },
        insured: {
          type: insured.clientType === 'individual' ? 'person' : 'company',
          address: {
            addressLine1: insured.address1,
            addressLine2: insured.address2,
            street: insured.address1,
            city: insured.city,
            postCode: insured.postalCode,
            countryCode: 'PL',
          },
          firstName: insured.firstName,
          lastName: insured.lastName,
          companyName: insured.companyName,
          identificationNumber: insured.idNumber,
        },
      },
      note: prepareNotes(selectedOffer),
    };
  } catch (e) {
    console.error('Error mapping offer lock payload', e);
  }
}
